<template>
  <v-list-item
    :key="item.href"
    dense
    :to="item.external ? '' : item.href"
    :href="item.external ? item.href : ''"
    :target="item.external ? '_blank' : '_self'"
    exact-active-class="active-item"
    class="pt-0 pb-0 pl-3 pr-3"
    @click="onSidebarLinkClick"
  >
    <v-list-item-action
      v-if="item.icon"
      class="my-0 mr-2 d-flex justify-center align-center"
    >
      <v-icon class="grey--text text--lighten-3">{{ item.icon }}</v-icon>
    </v-list-item-action>
    <v-list-item-content :class="{ 'ml-3': !item.icon }">
      <v-list-item-title
        class="grey--text text--lighten-3 font-weight-medium"
        style="white-space: normal"
      >
        {{ item.text }}
        <v-icon v-if="item.external" class="grey--text text--lighten-4 ml-1">
          fa-external-link-alt
        </v-icon>
      </v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    onSidebarLinkClick() {
      if (this.external) {
        return
      }

      if (this.item.reload) {
        this.$root.$emit("reset-search-params")
      }

      this.$root.$emit("sidebar-navigation", this.href)
    },
  },
}
</script>
